<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card v-if="!dataLoaded">
          <v-card-title>Academic Program/Advisor Change Form (Loading...)</v-card-title>
        </v-card>
        <change-status v-else-if="'program' in submission && submission.program.length > 0 && status !== 'Returned'" :submission="submission" :term-label="termLabel" @update="(data) => submission = data"></change-status>
        <template v-else>
          <v-toolbar>
            <v-toolbar-title>Academic Program/Advisor Change: {{ termLabel }}</v-toolbar-title>
          </v-toolbar>
          <v-card class="mb-2">
            <v-card-text>
              <p>This is where you can view your current major(s), minor(s), concentration(s), and certificate(s), and submit change requests for them.</p>
              <p>You can request to change your advisor below. Please note that you might be assigned a different advisor based on if the requested advisor is going on sabbatical, or to keep the department's advising duties balanced between professors, or based on a system developed by the department of your major.</p>
            </v-card-text>
          </v-card>
          <v-form ref="form" v-model="formValid">
            <program-block
              v-for="(program, index) of programs"
              :key="'prog-' + program.major"
              :program="program"
              :majors="majorItems"
              :has-second="programs.length > 1"
              :disable-majors="programs.filter(({ major }) => major !== program.major).map(({ major }) => major)"
              @update="(val) => programs.splice(index, 1, val)"
              @remove="programs.splice(index, 1)"
              ></program-block>
            <v-btn v-if="programs.length === 1" :disabled="programs[0].major === ''" outlined @click="programs.push({ major: '', advisor: '', conc: [] })">
              <v-icon left>fal fa-plus-circle</v-icon>
              Add Second Major
            </v-btn>
            <v-card class="mt-2 mb-2">
              <v-card-text>
                <v-autocomplete v-model="minor" :items="minorItems" label="Minors (select up to 2)" clear-icon="fal fa-times-circle" chips deletable-chips clearable multiple></v-autocomplete>
              </v-card-text>
            </v-card>
            <cert-block
              v-for="(certificate, index) of certificates"
              :key="'cert-' + certificate.cert"
              :certificate="certificate"
              :disable-certs="certificates.filter(({ cert }) => cert !== certificate.cert).map(({ cert }) => cert)"
              @remove="certificates.splice(index, 1)"
              @update="(val) => certificates.splice(index, 1, val)"
            ></cert-block>
            <v-btn v-if="certificates.length < 2" :disabled="certificates.length === 1 && certificates[0].cert === ''" outlined class="mb-2" @click="certificates.push({ cert: '', advisor: '' })">
              <v-icon left>fal fa-plus-circle</v-icon>
              Add Certificate
            </v-btn>
            <v-card v-if="includeP12 || isEDSTMajor" class="mb-2">
              <v-card-text>
                <h4>Education or Teacher Certification</h4>
                <template v-if="includeP12">
                  <p>Students who desire to teach in a field from their selected major are encouraged to minor in Education followed by completion of the Master of Arts in Teaching degree at Covenant. Note: if you choose not to minor in Education, you are required to complete three pre-requisite education courses: Introduction to Teaching, Education Psychology, and Education of Exceptional Children.</p>
                  <v-checkbox v-model="P12Secondary" label="P-12 or Secondary (grades 6-12) Education - the five year secondary certification program including MAT degree"></v-checkbox>
                </template>
                <template v-if="isEDSTMajor">
                  <p>Students who desire middle grades certification should complete the fifth-year Master of Arts in Teaching degree at Covenant. You must also select two content fields in the box below.</p>
                  <v-select v-model="EDSTContentFields" :items="contentFieldItems" label="Content Fields (choose 2)" chips deletable-chips multiple></v-select>
                </template>
              </v-card-text>
            </v-card>
          </v-form>
          <v-toolbar flat style="margin-bottom:40px">
            <v-btn text to="/student" class="mr-2">Cancel</v-btn>
            <v-btn color="success" :disabled="!isValid" @click="save">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="setInitialData()">Reset</v-btn>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ref, computed, reactive, watch, onMounted } from '@vue/composition-api'
import { inArray } from '@/helpers/functions'
import { advisors, concentrations, IDSPrimaryConcentrations, certAdvisors } from '@/components/student/program-change/options'

export default {
  components: {
    changeStatus: () => import('@/components/student/program-change/status'),
    ProgramBlock: () => import('@/components/student/program-change/programBlock'),
    CertBlock: () => import('@/components/student/program-change/certBlock')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const required = ref([(v) => !!v || 'Required'])
    const dataLoaded = ref(false)
    const term = ref('')
    const termLabel = ref('')

    const service = root.$feathers.service('student/major-change')
    const submission = ref({})
    const status = computed(() => 'status' in submission.value ? submission.value.status : 'Unsubmitted')
    const original = reactive({})

    const origPrograms = ref([])
    const programs = ref([])
    const majorItems = ref([])

    const minor = ref([])
    const minorItems = ref([])
    watch(minor, () => {
      if (minor.value.length > 2) {
        minor.value.splice(2, 1)
      }
    })

    const origCertificates = ref([])
    const certificates = ref([])

    onMounted(async () => {
      if ('Records' in roles.value || 'Employee' in roles.value || 'All Adjuncts' in roles.value || 'Technology Services' in roles.value) {
        root.$router.replace('/student/program-change/admin')
        return
      }
      // Load the major options
      root.$feathers.service('forms/select-options').find({ query: { name: 'Major Code' } }).then(({ data }) => {
        root.$feathers.service('forms/select-options').get(data[0].value).then((data) => {
          majorItems.value = data.options
        })
      })
      // Load the minor options
      root.$feathers.service('forms/select-options').find({ query: { name: 'Minor Code' } }).then(({ data }) => {
        root.$feathers.service('forms/select-options').get(data[0].value).then((data) => {
          minorItems.value = data.options
        })
      })
      // Get possible terms from the term warehouse; all terms that have not yet ended
      let { data: termData } = await root.$feathers.service('system/term').find({ query: { end: { $gt: new Date() } } })
      let terms = []
      for (let i = 0; i < termData.length; i++) {
        terms.push(termData[i].term)
      }
      // Load the PIDM of the current user
      let pidm = user.value.pidm
      // Check to see if the student has a submission for any of the given terms; if so, we will use that to populate the submission and the original data, in the case where they are making further changes
      const { data: changeData } = await service.find({ query: { pidm, term: { $in: terms } } })
      if (changeData.length > 0) {
        submission.value = changeData[0]
        if (status.value === 'Submitted' || status.value === 'Pending' || status.value === 'Approved') {
          dataLoaded.value = true
          return
        }
      }
      // Load the student's academic data from the student/term-detail for any of the active or future terms, sorted with the term code ascending
      // This will load the current term first, if there is a record for it, or for the next term to begin
      let { data: studentData } = await root.$feathers.service('student/term-detail').find({ query: { pidm, term: { $in: terms }, $sort: { term: 1 } } })
      if (studentData.length > 0) {
        let { academics, term: termCode } = studentData[0]
        term.value = termCode
        const { data: termData } = await root.$feathers.service('system/term').find({ query: { term: termCode } })
        if (termData.length > 0) termLabel.value = termData[0].label
        for (let l in academics) {
          if (Array.isArray(academics[l])) original[l] = academics[l].filter((val) => val !== '')
          else original[l] = academics[l]
        }
        setInitialData()
        dataLoaded.value = true
      } else {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'No enrollment details were found for you in any active term. Please contact the Records office for assistance.' })
        root.$router.replace('/')
      }
    })

    function setInitialData () {
      programs.value = []
      certificates.value = []
      // If the "original" data is not populated, then we should load the pending submission;
      // if the original data is populated, then we will use what is in the term-detail warehouse regardless of whether they have submitted a form this semester or not
      if ('program' in submission.value) {
        programs.value = submission.value.program
        minor.value = submission.value.minor || []
        certificates.value = submission.value.certificate || []
        P12Secondary.value = submission.value.p12 || false
        EDSTContentFields.value = submission.value.contentFields || []
      } else {
        const origMajrAdvr = original.advisor.filter(({ code }) => code === 'MAJR').map(({ pidm }) => pidm)
        for (const major of original.major) {
          const prog = { major, advisor: '', conc: [], primary: '' }
          // Look for an advisor that is in the list of available ones for this major
          if (major in advisors) {
            for (const { value } of advisors[major]) {
              if (inArray(value, origMajrAdvr)) {
                prog.advisor = value
              }
            }
          }
          // Look at the concentrations for this major and see if any of their concentrations match
          if (major in concentrations && original.concentration.length > 0) {
            if (major === 'IDS') {
              // We are considering the first concentration as the primary
              if (inArray(original.concentration[0], IDSPrimaryConcentrations.map(({ value }) => value))) {
                prog.primary = original.concentration[0]
              } else if (inArray(original.concentration[1], IDSPrimaryConcentrations.map(({ value }) => value))) {
                // Just in case the first one is not a primary, but this shouldn't ever happen
                prog.primary = original.concentration[1]
              }
              for (let i = 1; i < original.concentration.length; i++) {
                prog.conc.push(original.concentration[i])
              }
            } else {
              for (const { value } of concentrations[major]) {
                if (inArray(value, original.concentration)) {
                  prog.conc.push(value)
                }
              }
            }
          }
          programs.value.push(prog)
        }
        origPrograms.value = programs.value
        minor.value = [ ...original.minor ] || []
        const origCertAdv = original.advisor.filter(({ code }) => code === 'CERT').map(({ pidm }) => pidm)
        for (const cert of original.certificate) {
          const temp = { cert, advisor: '' }
          if (cert in certAdvisors) {
            for (const { value } of certAdvisors[cert]) {
              if (inArray(value, origCertAdv)) {
                temp.advisor = value
              }
            }
            if (temp.advisor === '' && certAdvisors[cert].length === 1) {
              temp.advisor = certAdvisors[cert][0].value
            }
          }
          certificates.value.push(temp)
        }
        origCertificates.value = certificates.value
      }
    }

    async function save () {
      if (form.value.validate()) {
        console.log('Form is valid')
      }
      const obj = {
        term: term.value,
        pidm: user.value.pidm,
        bannerId: user.value.bannerId,
        name: user.value.name,
        status: 'Submitted',
        program: programs.value,
        minor: minor.value,
        certificate: certificates.value,
        p12: P12Secondary.value,
        contentFields: EDSTContentFields.value
      }
      const timeline = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: 'Request Submitted',
        icon: 'fal fa-save',
        color: 'info',
        visibleToStudent: true
      }
      console.log(obj)
      let { data } = await service.find({ query: { term: term.value, pidm: user.value.pidm } })
      if (data.length > 0) {
        obj.$push = { timeline }
        await service.patch(data[0]._id, obj)
      } else {
        obj.timeline = [timeline]
        await service.create(obj)
      }
      await root.$feathers.service('system/email').create({
        from: { email: 'report-email@covenant.edu', name: 'Program Change' },
        to: 'recordsoffice@covenant.edu',
        // to: 'jon.moon@covenant.edu',
        subject: 'Program/Advisor Change Submitted',
        html: user.value.preferred + ' ' + user.value.last + ' has submitted a program/advisor change. Please click on the link below to access the Program/Advisor Change system to review this request.<br/><br/><a href="https://portal.covenant.edu/student/program-change">https://portal.covenant.edu/student/program-change</a>'
      })
      root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Your program/advisor change form was submitted successfully.' })
      root.$router.push('/student/program-change/status')
    }

    /** Education add-ons */
    const eduMajors = {
      ART: true,
      BIB: true,
      BIO: true,
      CHE: true,
      COS: true,
      ECO: true,
      ENG: true,
      FRE: true,
      GER: true,
      HIS: true,
      MAT: true,
      MUS: true,
      PHY: true,
      POLS: true,
      SPA: true,
      THET: true
    }
    const includeP12 = computed(() => programs.value.reduce((prev, { major }) => prev || major in eduMajors, false))
    const P12Secondary = ref(false)
    watch(P12Secondary, (val) => {
      if (val && !minor.value.reduce((prev, minr) => prev || minr === 'EDU', false)) {
        minor.value.push('EDU')
      }
    }, {
      lazy: true
    })
    watch(minor, () => {
      if (P12Secondary.value && !inArray('EDU', minor.value)) {
        P12Secondary.value = false
      }
    })

    const isEDSTMajor = computed(() => programs.value.reduce((prev, { major }) => prev || major === 'EDST', false))
    const EDSTContentFields = ref([])
    const contentFieldItems = ref([
      { value: 'EDLA', text: 'Language Arts' },
      { value: 'EDMA', text: 'Mathematics' },
      { value: 'EDSC', text: 'Science' },
      { value: 'EDSS', text: 'Social Studies' }
    ])
    watch(EDSTContentFields, () => {
      if (EDSTContentFields.value.length > 2) {
        EDSTContentFields.value.splice(2, 1)
      }
    })

    const isValid = computed(() => {
      // for (const { major } of programs.value) {
      //   if (major == null || major === '') return false
      // }
      // if (isEDSTMajor.value && EDSTContentFields.value.length < 2) return false
      return true
    })

    const form = ref(null)
    const formValid = ref(false)

    return {
      user,
      roles,
      required,
      dataLoaded,
      term,
      termLabel,
      submission,
      status,
      original,
      origPrograms,
      programs,
      majorItems,
      minor,
      minorItems,
      origCertificates,
      certificates,
      setInitialData,
      save,
      includeP12,
      P12Secondary,
      isEDSTMajor,
      EDSTContentFields,
      contentFieldItems,
      isValid,
      form,
      formValid
    }
  }
}

// function findDiffs (original, updated) {
//   const rem = original.filter((val) => !inArray(val, updated))
//   const add = updated.filter((val) => !inArray(val, original))
//   return { add, rem }
// }
</script>
